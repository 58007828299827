<template><div></div></template>

<script>
import Console from '@/console'
import { mapActions } from 'vuex'
import { fetchUserData } from '@/hydrate'

export default {
  data() {
    return {
      error: ''
    }
  },
  computed: {
    state() {
      return this.$route.query.state
    },

    code() {
      return this.$route.query.code
    },

    authProvider() {
      return this.$route.name.replace('Oauth', '')
    }
  },

  async mounted() {
    if (!this.code) {
      this.error = 'nocode'
      Console.warning('No code provided')
    } else {
      try {
        await this.authenticateSSO({
          provider: this.authProvider,
          state: this.state,
          code: this.code
        })
        await fetchUserData(this.$store)
      } catch (e) {
        this.error = 'badcode'
      }
    }

    this.handleOauthResult()
  },

  methods: {
    ...mapActions(['authenticateSSO']),

    handleOauthResult() {
      window.opener.focus()
      const error = this.error
      if (error) {
        let errorRoute = this.$router.resolve({
          name: 'login',
          query: {
            error
          }
        })
        window.opener.location.href = errorRoute.href
      }

      setTimeout(() => {
        window.opener.location.reload()
        setTimeout(() => {
          window.close()
        }, 250)
      }, 250)
    }
  }
}
</script>

<style></style>
